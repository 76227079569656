import {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import {STICKER_TYPES} from '@frontend/ui-kit/Components/Sticker';
import {ROUTES, PLAN_PERIOD_TYPES} from '../../../constants';
import {formatDate, equal} from '../../../utils';

const TIMELINE_UNAVAILABILITY_EXPLANATION = {
    title: 'Timeline Unavailable',
    content: `
        The Renewal Timeline is used within 60 days of your Plan Renewal Date. Until then there is no action to be taken there.
        If you believe you should be seeing a Renewal Timeline, please contact your Customer Success Team.
    `
};

const REPORTING_UNAVAILABILITY_EXPLANATION = {
    title: 'Reporting Unavailable',
    content: `
        HealthJoy reporting will be available within 24 hours of your launch. Once live you will be able to view how your company is using HealthJoy in real-time here including insights into utilization, program savings, and employee engagement.
    `
};

const BROADCASTER_UNAVAILABILITY_EXPLANATION = {
    title: 'Broadcaster Unavailable',
    content: 'Broadcaster will be available to send benefits communications to your employees the day after your launch date'
};

const PLAN_PERIOD_LABELS_MAP = {
    [PLAN_PERIOD_TYPES.renewal]: 'Renewal',
    [PLAN_PERIOD_TYPES.inService]: 'Current',
    [PLAN_PERIOD_TYPES.past]: 'Plan Year'
};

const PLAN_PERIOD_LABEL_TYPES_MAP = {
    [PLAN_PERIOD_TYPES.renewal]: STICKER_TYPES.primary,
    [PLAN_PERIOD_TYPES.inService]: STICKER_TYPES.success,
    [PLAN_PERIOD_TYPES.past]: STICKER_TYPES.tertiary
};

export const getItems = props => {
    const {isNewReporting, routesUnavailabilityMap: unavailabilityMap, marketingPages, isBroadcasterOnboarded, planPeriods} = props;
    const reportingInitialRoute = unavailabilityMap[ROUTES.bookOfBusinessReporting].isUnavailable
        ? ROUTES.costContainmentReporting
        : ROUTES.bookOfBusinessReporting;

    return [
        {
            title: 'Home',
            icon: ICON_TYPES.dashboard,
            path: 'home',
            subItems: [
                {
                    title: 'Timeline',
                    icon: ICON_TYPES.dashboard,
                    path: 'timeline',
                    isUnavailable: unavailabilityMap[ROUTES.timeline].isUnavailable,
                    unavailabilityExplanation: TIMELINE_UNAVAILABILITY_EXPLANATION
                },
                {
                    title: 'Timeline Generation',
                    icon: ICON_TYPES.dashboard,
                    path: 'timeline_generation',
                    isHidden: unavailabilityMap[ROUTES.timelineGeneration].isHidden
                }
            ]
        },
        {
            title: 'Contacts &\nCompany',
            icon: ICON_TYPES.people,
            path: 'contacts_and_company',
            subItems: [
                {
                    title: 'Contacts',
                    icon: ICON_TYPES.newsfeed,
                    path: 'contacts'
                },
                {
                    title: 'Company Information',
                    icon: ICON_TYPES.tablet,
                    path: 'company_information',
                    isHidden: unavailabilityMap[ROUTES.companyInfo].isHidden
                }
            ]
        },
        {
            title: 'Benefits',
            icon: ICON_TYPES.benefits,
            path: 'benefits',
            subItems: planPeriods.map(planPeriod => {
                const {id, status, start_date: startDate, end_date: endDate} = planPeriod;
                const isPastPlanPeriod = equal(status, PLAN_PERIOD_TYPES.past);
                const title = [startDate, endDate].map(date => formatDate(date, 'MM/dd/yyyy')).join(' - ');
                const label = PLAN_PERIOD_LABELS_MAP[status];
                const labelType = PLAN_PERIOD_LABEL_TYPES_MAP[status];

                return {
                    title,
                    icon: ICON_TYPES.calendarChecked,
                    path: id,
                    label,
                    labelType,
                    subItems: [
                        {title: 'Core Overview', icon: ICON_TYPES.emergency, path: 'core_benefits'},
                        {title: 'Core Details', icon: ICON_TYPES.emergency, path: 'core_benefits_details'},
                        {title: 'Ancillary Overview', icon: ICON_TYPES.ancillary, path: 'ancillary_benefits'},
                        {title: 'Ancillary Details', icon: ICON_TYPES.ancillary, path: 'ancillary_benefits_details'},
                        ...(!isPastPlanPeriod ? [{title: 'Preview', icon: ICON_TYPES.phone, path: 'preview'}] : [])
                    ].map(subItem => {
                        const {isHidden} = unavailabilityMap[`${ROUTES.benefits}/:planPeriodId/${subItem.path}`] ?? {};

                        return {...subItem, isHidden};
                    })
                };
            })
        },
        {
            title: 'Eligibility',
            icon: ICON_TYPES.eligibility,
            path: 'eligibility',
            subItems: planPeriods.map(planPeriod => {
                const {id, status, start_date: startDate, end_date: endDate} = planPeriod;
                const title = [startDate, endDate].map(date => formatDate(date, 'MM/dd/yyyy')).join(' - ');
                const label = PLAN_PERIOD_LABELS_MAP[status];
                const labelType = PLAN_PERIOD_LABEL_TYPES_MAP[status];

                return {
                    title,
                    icon: ICON_TYPES.calendarChecked,
                    path: id,
                    label,
                    labelType,
                    subItems: [
                        {title: 'Eligibility Overview', icon: ICON_TYPES.list, path: 'implementation'},
                        {title: 'Eligibility Details', icon: ICON_TYPES.list, path: 'details'},
                        {title: 'Export Eligibility Files', icon: ICON_TYPES.clip, path: 'export'}
                    ].map(subItem => {
                        const {isHidden} = unavailabilityMap[`${ROUTES.eligibility}/:planPeriodId/${subItem.path}`] ?? {};

                        return {...subItem, isHidden};
                    })
                };
            })
        },
        // TODO: #REPORTING remove deprecated lines below as soon as Looker is released and tested (12.12.2024, Oleh);
        {
            title: 'Reporting',
            icon: ICON_TYPES.reporting,
            path: 'reporting',
            isHidden: isNewReporting,
            subItems: [
                {
                    title: 'Summary',
                    icon: ICON_TYPES.trendUp,
                    path: 'summary'
                },
                {
                    title: 'Program Savings',
                    icon: ICON_TYPES.moneyCouple,
                    path: 'program_savings'
                },
                {
                    title: 'Utilization',
                    icon: ICON_TYPES.settings,
                    path: 'utilization'
                },
                {
                    title: 'Member Activation',
                    icon: ICON_TYPES.memberInfo,
                    path: 'member_activation'
                },
                {
                    title: 'Download Reports',
                    icon: ICON_TYPES.fileFull,
                    path: 'download_reports'
                }
            ].map(subItem => {
                const {isUnavailable, isHidden} = unavailabilityMap[`/reporting/${subItem.path}`];

                return {...subItem, isUnavailable, isHidden, unavailabilityExplanation: REPORTING_UNAVAILABILITY_EXPLANATION};
            })
        },
        {
            title: 'Reporting',
            icon: ICON_TYPES.reporting,
            path: reportingInitialRoute.slice(1),
            isHidden: !isNewReporting,
            isUnavailable: unavailabilityMap[reportingInitialRoute].isUnavailable,
            unavailabilityExplanation: REPORTING_UNAVAILABILITY_EXPLANATION
        },
        {
            title: 'Education & Marketing',
            icon: ICON_TYPES.chat,
            path: 'education_and_marketing',
            isHidden: unavailabilityMap[`${ROUTES.educationAndMarketing}/:id`].isHidden || !marketingPages.length,
            subItems: marketingPages.map(subItem => {
                const {title, id} = subItem;

                return {title, path: id, icon: ICON_TYPES.listEm};
            })
        },
        {
            title: 'Broadcaster',
            icon: ICON_TYPES.speaker,
            path: 'broadcaster',
            isUnavailable: unavailabilityMap[ROUTES.broadcasterWelcome].isUnavailable,
            unavailabilityExplanation: BROADCASTER_UNAVAILABILITY_EXPLANATION,
            isHidden: unavailabilityMap[ROUTES.broadcasterWelcome].isHidden,
            isHighlighted: !isBroadcasterOnboarded
        }
    ];
};
