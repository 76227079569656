import React from 'react';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import PageHeader from '../../../Components/shared/PageHeader';
import ReportingPagesTabs from '../../../Components/Reporting/ReportingPagesTabs';
import BenefitsUsageReporting from '../../../Components/Reporting/BenefitsUsage';

const BenefitsUsage = () => {
    return (
        <React.Fragment>
            <Row>
                <Column sm>
                    <PageHeader title='Data Insights Dashboard'/>
                </Column>
            </Row>

            <ReportingPagesTabs/>

            <BenefitsUsageReporting/>
        </React.Fragment>
    );
};

export {BenefitsUsage as TestableBenefitsUsage};
export default React.memo(BenefitsUsage);
