import {ROUTES} from '../constants';

export const TRACKED_ROUTES = [
    ROUTES.login,
    ROUTES.timelineGeneration,
    ROUTES.timeline, // FYI: this route should be strictly after rest timeline pages (22.04.2024, Oleh);
    ROUTES.contacts,
    ROUTES.companyInfo,
    ROUTES.benefits,
    ROUTES.eligibility,
    // TODO: #REPORTING remove deprecated lines below as soon as Looker is released and tested (12.12.2024, Oleh);
    ROUTES.reportDownloadsReporting,
    ROUTES.programSavingsReporting,
    ROUTES.summaryReporting,
    ROUTES.utilizationReporting,

    ROUTES.bookOfBusinessReporting,
    ROUTES.costContainmentReporting,
    ROUTES.benefitsUsageReporting,
    ROUTES.completeRoiReporting,
    ROUTES.memberActivationReporting,
    ROUTES.educationAndMarketing,
    ROUTES.broadcastEditor,
    ROUTES.broadcastDetails,
    ROUTES.broadcasterWelcome // FYI: this route should be strictly after rest broadcaster pages (01.04.2021, Oleh);
];

export const TRACKED_SELECTIONS = {
    planPeriodSelection: 'planPeriodSelection',
    serviceUtilizationSelection: 'serviceUtilizationSelection'
};

export const TRACKED_SEARCHES = {
    memberActivationStatusSearch: 'memberActivationStatusSearch'
};

export const ANALYTICS_PLATFORM = 'Web';

export const ANALYTICS_EVENT_NAMES = {
    pageLoad: 'PageLoad',
    toggleSelection: 'ToggleSelection',
    download: 'Download',
    search: 'Search',
    externalLink: 'ExternalLink',
    successfulLoginAttempt: 'SuccessfulLoginAttempt',
    failedLoginAttempt: 'FailedLoginAttempt',
    clicked: 'Clicked',
    newBroadcast: 'NewBroadcast'
};
