import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import classnames from 'classnames';
import Heading, {HEADING_TYPES} from '@frontend/ui-kit/Components/Heading';
import {POPUP_TYPES} from '@frontend/ui-kit/Components/Popup';
import Text, {TEXT_TYPES} from '@frontend/ui-kit/Components/Text';
import ContentSection from '@frontend/ui-kit/Components/ContentSection';
import Collapse from '@frontend/ui-kit/Components/Collapse';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import AsyncAutocomplete from '@frontend/ui-kit/Components/AsyncAutocomplete';
import RadioGroup from '@frontend/ui-kit/Components/RadioGroup';
import Select from '@frontend/ui-kit/Components/Select';
import MultiSelectInput from '@frontend/ui-kit/Components/MultiSelectInput';
import Icon, {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import PopupContent from '@frontend/ui-kit/Components/PopupContent';
import {FieldArray} from '../../../shared/FormComponents';
import RevisionsField from '../../../shared/RevisionsField';
import useFieldArray from '../../../../hooks/useFieldArray';
import withPopup from '../../../../HOC/withPopup';
import {compose, equal, omit, promisifyAsyncFunction} from '../../../../utils';
import {requestInsuranceNetworks} from '../../../../actions/adminPortal';
import {
    FIND_CARE_NETWORK_PRIORITIES,
    FIND_CARE_NETWORK_RESTRICTIONS_OPTIONS,
    STATES_OPTIONS
} from '../../../../options';
import {FIND_CARE_NETWORK_RESTRICTIONS} from '../../../../constants';
import './index.scss';

const POPUP_ID = 'FindCareNetworksSettingsPopup';
const CORRECT_NETWORKS = 'medtool_plan.correct_networks';
const EMPTY_NETWORK = {names: [], priority: 1, restrictions: {type: FIND_CARE_NETWORK_RESTRICTIONS.all}};

const getCorrectNetworksFormData = data => {
    if (!data?.length) {
        return [EMPTY_NETWORK];
    }

    return data?.map(item => ({
        ...item,
        restrictions: item.restrictions || {type: FIND_CARE_NETWORK_RESTRICTIONS.all}
    }));
};

const getCorrectNetworksFormattedData = data => {
    const firstNetwork = data[0];

    if (equal(data.length, 1) && !firstNetwork.names.length) {
        return [];
    }

    return data?.map(item => equal(item.restrictions.type, FIND_CARE_NETWORK_RESTRICTIONS.all)
        ? omit(item, 'restrictions')
        : item);
};

const FindCareNetworksSettings = ({openPopup, closePopup}) => {
    const {fields} = useFieldArray(CORRECT_NETWORKS);
    const dispatch = useDispatch();

    const onAddNetworkSetting = () => {
        fields.push(EMPTY_NETWORK);
    };

    const onRemoveNetworkSetting = ({index, fields}) => () => {
        const onDelete = () => {
            fields.remove(index);
            closePopup();
        };

        const actionBar = (
            <React.Fragment>
                <Button type={BUTTON_TYPES.secondary} onClick={closePopup}>Cancel</Button>
                <Button type={BUTTON_TYPES.destructive} onClick={onDelete} data-testid='confirm-relete'>Yes, Delete</Button>
            </React.Fragment>
        );
        const popupContent = <Text>Please note deleting this Setting will remove all associated saved information and settings.</Text>;

        const popupProps = {title: `Are you sure you want to delete Network Setting #${index}?`, actionBar, children: popupContent};
        const children = <PopupContent {...popupProps}/>;

        return openPopup({type: POPUP_TYPES.simple, children});
    };

    const loadPharmacyBenefitManagerOptions = useCallback(promisifyAsyncFunction(async query => {
        const {data} = await dispatch(requestInsuranceNetworks({query}));

        return data.map(({name}) => ({label: name, value: name}));
    }), [dispatch]);

    const getNetworksSettings = ({fields}) => fields.map((field, index) => {
        const {restrictions} = fields.value[index];
        const isFirst = equal(index, 0);

        const content = (
            <div className='network-settings__item__content' data-testid='network-item'>
                <RevisionsField name={`${field}.names`}>
                    {props => (
                        <AsyncAutocomplete {...props}
                            description='Network Names used for Find Care. Select from autocomplete and repeat to add multiple values.'
                            placeholder='Type to select network(s)'
                            label='Find Care Network Name(s)'
                            loadOptions={loadPharmacyBenefitManagerOptions}
                            isCreatable={false}
                            isRequired={fields.value.length > 1}
                            isMulti/>
                    )}
                </RevisionsField>

                <RevisionsField name={`${field}.restrictions.type`}>
                    {props => <RadioGroup {...props} options={FIND_CARE_NETWORK_RESTRICTIONS_OPTIONS} disabled={isFirst} isInline/>}
                </RevisionsField>

                {equal(restrictions.type, FIND_CARE_NETWORK_RESTRICTIONS.state) && (
                    <RevisionsField name={`${field}.restrictions.values`}>
                        {props => <Select {...props} options={STATES_OPTIONS} label='Region' isRequired isMulti/>}
                    </RevisionsField>
                )}
                {equal(restrictions.type, FIND_CARE_NETWORK_RESTRICTIONS.zip) && (
                    <RevisionsField name={`${field}.restrictions.values`}>
                        {props => <MultiSelectInput {...props} pattern='[0-9]' label='Zip code' isRequired/>}
                    </RevisionsField>
                )}

                <RevisionsField name={`${field}.priority`}>
                    {props => <Select {...props} options={FIND_CARE_NETWORK_PRIORITIES} label='Priority Between Networks'/>}
                </RevisionsField>
            </div>
        );

        return (
            <ContentSection className={classnames('network-settings__item', {'network-settings__item-collapse': !isFirst})}>
                {isFirst && (
                    <React.Fragment>
                        <Text className='mb-12' type={TEXT_TYPES.bodyBold}>Network Setting</Text>
                        {content}
                    </React.Fragment>
                )}
                {!isFirst && (
                    <Collapse hasCollapseIcon
                        collapseIconClassName='collapse-icon'
                        initiator={<Text type={TEXT_TYPES.bodyBold}>{`Advanced Network Setting #${index}`}</Text>}
                        content={content}
                        actionBar={(
                            <Icon className='network-settings__item__remove' type={ICON_TYPES.delete} onClick={onRemoveNetworkSetting({index, fields})} data-testid='network-item-remove'/>
                        )}/>
                )}
            </ContentSection>
        );
    });

    return (
        <div>
            <Heading type={HEADING_TYPES['5']} className='mb-10'>Find Care Network Setting</Heading>

            <div className='network-settings'>
                <FieldArray name={CORRECT_NETWORKS}>{getNetworksSettings}</FieldArray>
                <Button type={BUTTON_TYPES.tertiary} onClick={onAddNetworkSetting} data-testid='add-network-setting'>
                    <Icon type={ICON_TYPES.add}/> Add Advanced Settings
                </Button>
            </div>
        </div>
    );
};

FindCareNetworksSettings.propTypes = {
    openPopup: PropTypes.func.isRequired,
    closePopup: PropTypes.func.isRequired
};

export {getCorrectNetworksFormData, getCorrectNetworksFormattedData, EMPTY_NETWORK, FindCareNetworksSettings as TestableFindCareNetworksSettings};
export default compose(
    withPopup(POPUP_ID),
    React.memo
)(FindCareNetworksSettings);
