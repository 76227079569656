import React, {useState} from 'react';
import Heading, {HEADING_TYPES} from '@frontend/ui-kit/Components/Heading';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Text from '@frontend/ui-kit/Components/Text';
import Layout from '../Layout';
import LookerDashboard from '../LookerDashboard';
import DownloadReportButton from '../DownloadReportButton';
import {LOOKER_URL, LOOKER_URLS} from '../../../constants';

const DASHBOARD_ID = {
    [LOOKER_URLS.dev]: 50
}[LOOKER_URL];

const CompleteRoi = () => {
    const [filters, setFilters] = useState(null);

    return (
        <Layout>
            <div className='reporting'>
                <Row between='sm'>
                    <Column sm={9}>
                        <Heading className='section-heading' type={HEADING_TYPES['3']}>
                            Complete ROI
                        </Heading>
                        <Text className='reporting-info'>
                            An aggregate view of savings across your HealthJoy programs.
                        </Text>
                    </Column>
                    <Column sm constant>
                        <DownloadReportButton dashboardId={DASHBOARD_ID} filters={filters}/>
                    </Column>
                </Row>

                <LookerDashboard dashboardId={DASHBOARD_ID} onUpdateFilters={setFilters}/>
            </div>
        </Layout>
    );
};

export {CompleteRoi as TestableCompleteRoi};
export default React.memo(CompleteRoi);
