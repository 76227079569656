import React from 'react';
import {useSelector} from 'react-redux';
import PagesTabs from '../../../Containers/shared/PagesTabs';
import {getIsNewReporting} from '../../../selectors/general';
import {ROUTES} from '../../../constants';

// TODO: #REPORTING remove deprecated lines below as soon as Looker is released and tested (12.12.2024, Oleh);
const OLD_REPORTING_ROUTES = [
    ROUTES.summaryReporting,
    ROUTES.programSavingsReporting,
    ROUTES.utilizationReporting,
    ROUTES.memberActivationReporting,
    ROUTES.reportDownloadsReporting
];

const REPORTING_ROUTES = [
    ROUTES.bookOfBusinessReporting,
    ROUTES.costContainmentReporting,
    ROUTES.benefitsUsageReporting,
    ROUTES.completeRoiReporting,
    ROUTES.memberActivationReporting
];

const ReportingPagesTabs = () => {
    // TODO: #REPORTING remove deprecated lines below as soon as Looker is released and tested (12.12.2024, Oleh);
    const isNewReporting = useSelector(getIsNewReporting);
    const routes = isNewReporting ? REPORTING_ROUTES : OLD_REPORTING_ROUTES;

    return <PagesTabs routes={routes}/>;
};

export {ReportingPagesTabs as TestableReportingPagesTabs};
export default ReportingPagesTabs;
