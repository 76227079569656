import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import Button from '@frontend/ui-kit/Components/Button';
import Icon, {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import './index.scss';

const DownloadReportButton = ({dashboardId, filters}) => {
    const onDownload = useCallback(() => {
        console.log(dashboardId);
        console.log(filters);
    }, [dashboardId, filters]);

    return (
        <Button className='download-report-button'
            iconLeft={<Icon type={ICON_TYPES.download}/>}
            onClick={onDownload}>
            Download Page
        </Button>
    );
};

DownloadReportButton.propTypes = {
    dashboardId: PropTypes.number,
    filters: PropTypes.shape({})
};

export {DownloadReportButton as TestableDownloadReportButton};
export default React.memo(DownloadReportButton);
