import React, {useState, useMemo, useCallback, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import Heading, {HEADING_TYPES} from '@frontend/ui-kit/Components/Heading';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Text from '@frontend/ui-kit/Components/Text';
import Tabs from '@frontend/ui-kit/Components/Tabs';
import Layout from '../Layout';
import LookerDashboard from '../LookerDashboard';
import DownloadReportButton from '../DownloadReportButton';
import {redirectTo} from '../../../actions/general';
import {equal, getEqual, getItemKeyValue} from '../../../utils';
import {LOOKER_URL, LOOKER_URLS, ROUTES} from '../../../constants';

const UTILIZATION_TABS = [
    {title: 'Service Utilization', type: 'service_utilization'},
    {title: 'Requests', type: 'requests'}
];

const SERVICE_UTILIZATION_DASHBOARD_ID = {
    [LOOKER_URLS.dev]: 23
}[LOOKER_URL];
const REQUESTS_DASHBOARD_ID = {
    [LOOKER_URLS.dev]: 26
}[LOOKER_URL];

const getActiveTabIndex = type => {
    const tabsIndex = UTILIZATION_TABS.findIndex(getEqual(type, 'type'));

    return equal(tabsIndex, -1) ? 0 : tabsIndex;
};

const BenefitsUsage = () => {
    const dispatch = useDispatch();
    const {type} = useParams();
    const [activeTab, setActiveTab] = useState(getActiveTabIndex(type));
    const [filters, setFilters] = useState(null);
    const tabs = useMemo(() => UTILIZATION_TABS.map(getItemKeyValue('title')), []);

    const redirectToTab = useCallback(tabIndex => dispatch(redirectTo(`${ROUTES.benefitsUsageReporting}/${UTILIZATION_TABS[tabIndex]?.type}`)), [dispatch]);
    const onChangeTab = useCallback(activeTabIndex => redirectToTab(activeTabIndex), [redirectToTab]);

    useEffect(() => {
        const activeTabIndex = getActiveTabIndex(type);

        if (!type) {
            redirectToTab(activeTabIndex);
            return;
        }

        setActiveTab(activeTabIndex);
    }, [type, redirectToTab]);

    return (
        <Layout>
            <div className='reporting'>
                <Row between='sm'>
                    <Column sm={9}>
                        <Heading className='section-heading' type={HEADING_TYPES['3']}>
                            Benefits Usage
                        </Heading>
                        <Text className='reporting-info'>
                            View a breakdown of member utilization and satisfaction of HealthJoy programs and services, and the resulting savings.
                        </Text>
                    </Column>
                    <Column sm constant>
                        <DownloadReportButton filters={filters}
                            dashboardId={equal(activeTab, 0) ? SERVICE_UTILIZATION_DASHBOARD_ID : REQUESTS_DASHBOARD_ID}/>
                    </Column>
                </Row>

                <Tabs className='mb-20' tabs={tabs} onChange={onChangeTab} activeTab={activeTab}/>

                {equal(activeTab, 0) && <LookerDashboard dashboardId={SERVICE_UTILIZATION_DASHBOARD_ID} onUpdateFilters={setFilters}/>}
                {equal(activeTab, 1) && <LookerDashboard dashboardId={REQUESTS_DASHBOARD_ID} onUpdateFilters={setFilters}/>}
            </div>
        </Layout>
    );
};

export {BenefitsUsage as TestableBenefitsUsage};
export default React.memo(BenefitsUsage);
