import {getActiveCompany} from '../selectors/general';
import {
    RECEIVE_REPORTING_INFO,
    SET_REPORTING_PLAN_PERIOD,
    SET_ACTIVE_REPORTING_TAB,
    DOWNLOAD_ACTIVATION_REPORT,
    DOWNLOAD_PERFORMANCE_REPORT,
    REQUEST_SEARCHED_MEMBERS
} from './actionTypes';
import apiServices from '../apiServices';
import {LOCAL_STORAGE_ITEMS, HTTP_RESPONSE_TYPES} from '../constants';
import {getReportingMetricInfo, formatChartLabels} from '../helpers';
import {saveFile} from '../utils';

const formatMonthlyChartData = (chartData = {}, companyData = {}) => {
    const {datasets = [], labels = []} = chartData;
    const formattedLabels = formatChartLabels(labels, 'MMMM yyyy');
    const enhancedDatasets = datasets.map(({id, ...dataset}) => ({...dataset, ...getReportingMetricInfo(id, companyData)}));

    return {labels: formattedLabels, datasets: enhancedDatasets};
};
const formatProportionChartData = (chartData = {}, companyData = {}) => {
    const {datasets = [], labels = []} = chartData;
    const formattedLabels = labels.map(id => getReportingMetricInfo(id, companyData)?.label);

    return {labels: formattedLabels, datasets};
};

const INFO_FORMATTER_BY_SECTION = {
    summary: info => ({
        roi: info.roi_rate,
        totalSavings: info.total_savings,
        memberSatisfaction: info.satisfaction_rating,
        satisfactionTotalReviews: info.satisfaction_total_reviews,
        utilizationTicketsCount: info.utilization_tickets_count,
        activatedEmployeesCount: info.current_activation_count_activated,
        eligibleEmployeesCount: info.current_activation_count_eligible,
        activationRating: info.activation_rating,
        activationChartData: formatMonthlyChartData(info.employee_activation_chart)
    }),
    program_savings: info => ({
        totalSavings: info.total_savings,
        telemedSavings: info.telemed_savings,
        teladocGeneralMedicalSavings: info.teladoc_general_medical_savings,
        teladocMentalHealthSavings: info.teladoc_mental_health_savings,
        rxSavings: info.rx_savings,
        mskSavings: info.msk_savings,
        preCertificationSavings: info.precert_savings,
        providerFacilitySavings: info.provider_facility_savings,
        medicalBillReviewSavings: info.bill_savings,
        totalSavingsChartData: formatMonthlyChartData(info.total_savings_chart),
        roiChartData: formatMonthlyChartData(info.roi_chart),
        savingsByServiceChartData: formatMonthlyChartData(info.productivity_savings_chart, {show_find_care: info.show_find_care})
    }),
    utilization: info => ({
        eligibleEmployeesCount: info.current_activation_count_eligible,
        utilizationTicketsCount: info.utilization_tickets_count,
        telemedicineConsultsCount: info.telemedicine_consults,
        teladocGeneralMedicalConsultsCount: info.teladoc_general_medical_consults,
        teladocMentalHealthConsultsCount: info.teladoc_mental_health_consults,
        teladocGeneralMedicalAnnualizedRate: info.teladoc_gen_med_annualized_telemed,
        teladocMentalHealthAnnualizedRate: info.teladoc_mental_health_annualized_telemed,
        teladocGeneralMedicalAnnualizedTarget: info.teladoc_telemed_utilization_target,
        teladocMentalHealthAnnualizedTarget: info.teladoc_mental_health_utilization_target,
        walletViewsCount: info.wallet_views,
        serviceRequestsCount: info.service_requests,
        providerRecommendationsCount: info.provider_recommendations,
        appointmentsCount: info.appointments_scheduled,
        facilityRecommendationsCount: info.facility_recommendations,
        findCareSearchesCount: info.find_care_searches,
        rxReviewsCount: info.rx_reviews,
        medicalBillReviewsCount: info.bill_reviews,
        benefitsSupportCount: info.benefits_support,
        preCertificationCasesCount: info.precert_total_cases,
        preCertificationSavings: info.precert_savings,
        mskEnrollmentsCount: info.msk_enrollments,
        memberUtilizationChartData: formatMonthlyChartData(info.member_utilization_chart),
        medicalBillReviewUtilizationChartData: formatMonthlyChartData(info.medical_bill_review_utilization_chart),
        benefitsSupportUtilizationChartData: formatMonthlyChartData(info.benefits_support_utilization_chart),
        benefitsWalletUtilizationChartData: formatMonthlyChartData(info.benefits_wallet_utilization_chart),
        rxSavingsUtilizationChartData: formatMonthlyChartData(info.rx_savings_utilization_chart),
        appointmentUtilizationChartData: formatMonthlyChartData(info.appointment_scheduling_utilization_chart),
        facilityRecommendationUtilizationChartData: formatMonthlyChartData(info.facility_recommendation_utilization_chart),
        findCareSearchUtilizationChartData: formatMonthlyChartData(info.find_care_search_utilization_chart),
        providerRecommendationUtilizationChartData: formatMonthlyChartData(info.provider_recommendation_utilization_chart),
        totalServiceRequestsChartData: formatMonthlyChartData(info.total_service_requests_chart),
        telemedUtilizationChartData: formatMonthlyChartData(info.telemedicine_consults_chart),
        teladocHealthUtilizationChartData: formatMonthlyChartData(info.teladoc_health_utilization_chart),
        preCertificationChartData: formatMonthlyChartData(info.precert_chart),
        preCertificationTotalChartData: formatProportionChartData(info.precert_total_chart),
        mskEnrollmentsChartData: formatMonthlyChartData(info.msk_enrollments_chart)
    })
};

const receiveReportingInfo = info => ({type: RECEIVE_REPORTING_INFO, info});
const downloadActivationReport = fileDetails => ({type: DOWNLOAD_ACTIVATION_REPORT, fileDetails});
const downloadPerformanceReport = fileDetails => ({type: DOWNLOAD_PERFORMANCE_REPORT, fileDetails});
const requestSearchedMembers = ({query}) => ({type: REQUEST_SEARCHED_MEMBERS, query}); // FYI: action for analyticsTracker (Vlad, 06.07.20)
export const setActiveReportingTab = activeTab => ({type: SET_ACTIVE_REPORTING_TAB, activeTab});

export const setReportingPlanPeriod = planPeriod => {
    localStorage.setItem(LOCAL_STORAGE_ITEMS.reportingPlanPeriod, planPeriod);

    return {type: SET_REPORTING_PLAN_PERIOD, planPeriod};
};

export const requestReportingInfo = (reportingSection, reportingPlanPeriod) => async (dispatch, getState) => {
    const state = getState();
    const {alias: companyAlias} = getActiveCompany(state);

    const {data, isSuccess} = await apiServices.getReportingInfo({companyAlias, reportingSection, reportingPlanPeriod});

    if (!isSuccess) {
        return false;
    }

    const formattedInfo = {
        error: data.error,
        isRoiVisible: data.show_roi,
        isTelemedVisible: data.show_telemed,
        isTeladocVisible: data.show_teladoc_general_medical || data.show_teladoc_mental_health,
        isTeladocGeneralMedicalVisible: data.show_teladoc_general_medical,
        isTeladocMentalHealthVisible: data.show_teladoc_mental_health,
        isMedicalBillVisible: data.show_mbr,
        isMskVisible: data.show_msk,
        isPreCertificationVisible: data.show_precert,
        isFindCareVisible: data.show_find_care,
        [reportingSection]: INFO_FORMATTER_BY_SECTION[reportingSection](data)
    };

    dispatch(receiveReportingInfo(formattedInfo));
};

export const requestDownloadReports = reportingPlanPeriod => async (dispatch, getState) => {
    const state = getState();
    const {alias: companyAlias} = getActiveCompany(state);

    const {data: downloadReports = [], isSuccess} = await apiServices.getDownloadReports({companyAlias, reportingPlanPeriod});

    if (!isSuccess) {
        return false;
    }

    dispatch(receiveReportingInfo({download_reports: downloadReports}));
};

export const requestMembers = params => async (dispatch, getState) => {
    const state = getState();
    const {id: companyId} = getActiveCompany(state);

    dispatch(requestSearchedMembers(params));

    const {data, isSuccess} = await apiServices.getSearchedCompanyEmployees({companyId, ...params});
    const {employees: members = [], count = 0, show_location: isLocationShowed} = isSuccess ? data : {};

    dispatch(receiveReportingInfo({member_activation: {members}}));

    return {members, count, isLocationShowed, isSuccess};
};

export const requestMembersReport = () => async (dispatch, getState) => {
    const state = getState();
    const {id: companyId} = getActiveCompany(state);

    const {data: reportFile, isSuccess, headers} = await apiServices.getCompanyEmployeesReport({companyId});

    if (!isSuccess) {
        return false;
    }

    const {'content-disposition': disposition} = headers;
    const [name] = disposition.split('=').reverse();

    saveFile(reportFile, name);

    dispatch(downloadActivationReport({name}));
};

export const requestPerformanceReport = fileDetails => async dispatch => {
    const {url, name} = fileDetails;
    const {data: reportFile, isSuccess} = await apiServices.getFromThirdParty(url, null, {responseType: HTTP_RESPONSE_TYPES.blob});

    if (!isSuccess) {
        return false;
    }

    saveFile(reportFile, name);

    dispatch(downloadPerformanceReport(fileDetails));
};

export const requestReportingDashboardHiddenTiles = dashboardId => async () => {
    const {data, isSuccess} = await apiServices.getReportingDashboardHiddenTiles({dashboardId});
    const hiddenTiles = isSuccess ? data.tiles_ids : [];

    return {hiddenTiles, isSuccess};
};

export const requestReportDownloading = (dashboardId, filters) => async dispatch => {
    const {data: reportFile, isSuccess} = await apiServices.setReportDownloading({dashboardId, filters});

    if (!isSuccess) {
        return false;
    }

    saveFile(reportFile, name);
};
